import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  select: PropTypes.func.isRequired,
};

const ActionSelector = (props) => {
  const selectAction = () => props.select('switch');
  return (
    <button className="switchAction" type="button" onClick={selectAction}>
      <span role="img" aria-label="switch decks">🔁</span>
    </button>
  );
};

ActionSelector.propTypes = propTypes;

export default ActionSelector;
