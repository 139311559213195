import React from 'react';
import { map, groupBy } from 'lodash';
import PropTypes from 'prop-types';
import { categoryForCard, buildOperations } from '../util';

import OperationsSection from './OperationsSection';

import cardData from '../data/card_data_cache.json';

const Operations = (props) => {
  const {
    operation, targetDeck, sourceDeck, staples,
  } = props;
  const operations = buildOperations(operation, targetDeck, sourceDeck, staples);
  const operationsWithGroups = map(operations, (op) => {
    const cardInfos = map(op.cardNames, (cardName) => cardData.cards[cardName]);
    return { ...op, groupedCards: groupBy(cardInfos, categoryForCard) };
  });
  return (
    <div className="Operations">
      {
        map(operationsWithGroups, (op) => (
          <OperationsSection
            key={op.name}
            name={op.description}
            groupedCards={op.groupedCards}
          />
        ))
      }
    </div>
  );
};

Operations.propTypes = {
  operation: PropTypes.string.isRequired,
  targetDeck: PropTypes.arrayOf(PropTypes.string).isRequired,
  sourceDeck: PropTypes.arrayOf(PropTypes.string),
  staples: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Operations.defaultProps = {
  sourceDeck: null,
};

export default Operations;
