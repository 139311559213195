import { remove } from 'lodash';

const landGroupDefinitions = [
  {
    name: 'fetch',
    cards: [
      'Flooded Strand',
      'Polluted Delta',
      'Bloodstained Mire',
      'Wooded Foothills',
      'Windswept Heath',
      'Marsh Flats',
      'Scalding Tarn',
      'Verdant Catacombs',
      'Arid Mesa',
      'Misty Rainforest',
    ],
  },
  {
    name: 'dual',
    cards: [
      'Tundra',
      'Underground Sea',
      'Badlands',
      'Taiga',
      'Savannah',
      'Scrubland',
      'Volcanic Island',
      'Bayou',
      'Plateau',
      'Tropical Island',
    ],
  },
  {
    name: 'shock',
    cards: [
      'Hallowed Fountain',
      'Watery Grave',
      'Blood Crypt',
      'Stomping Ground',
      'Temple Garden',
      'Godless Shrine',
      'Steam Vents',
      'Overgrown Tomb',
      'Sacred Foundry',
      'Breeding Pool',
    ],
  },
];

const subdivideLands = (allCards) => {
  const unsubdividedLands = [...allCards];
  const landGroups = [];
  for (const group of landGroupDefinitions) {
    const landGroup = {
      name: group.name,
      cards: [],
    };
    for (const card of allCards) {
      if (group.cards.indexOf(card.name) !== -1) {
        landGroup.cards.push(card);
        remove(unsubdividedLands, card);
      }
    }
    if (landGroup.cards.length > 0) {
      landGroups.push(landGroup);
    }
  }
  return landGroups.concat([{ name: 'unknown', cards: unsubdividedLands }]);
};

export { subdivideLands };
