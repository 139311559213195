import React from 'react';
import { map } from 'lodash';
import PropTypes from 'prop-types';

import CardList from './CardList';
import { CARD_GROUP_TYPE } from '../propTypes';

const propTypes = {
  name: PropTypes.string.isRequired,
  cardGroups: PropTypes.arrayOf(CARD_GROUP_TYPE),
};

const defaultProps = {
  cardGroups: [],
};

const CardCategory = (props) => {
  const { cardGroups, name } = props;
  const groups = map(cardGroups, (group) => <CardList key={group.name} cards={group.cards} />);
  return (
    <div className="CardCategory">
      <h3>{name}</h3>
      {groups}
    </div>
  );
};

CardCategory.propTypes = propTypes;
CardCategory.defaultProps = defaultProps;

export default CardCategory;
