import React from 'react';
import { map } from 'lodash';
import PropTypes from 'prop-types';

import CardListItem from './CardListItem';
import { CARD_TYPE } from '../propTypes';
import './CardList.css';

const propTypes = {
  cards: PropTypes.arrayOf(CARD_TYPE),
};

const defaultProps = {
  cards: [],
};

const CardList = (props) => {
  const { cards } = props;
  const cardComponents = map(cards, (card) => <CardListItem key={card.name} card={card} />);
  return <div className="CardList">{cardComponents}</div>;
};

CardList.propTypes = propTypes;
CardList.defaultProps = defaultProps;

export default CardList;
