import React from 'react';
import { map, reduce } from 'lodash';
import PropTypes from 'prop-types';

import { orderedCategories } from '../util';
import CardCategory from './CardCategory';
import { subdivideLands } from '../land_util';
import { CARD_TYPE } from '../propTypes';

import './OperationsSection.css';

const GROUPED_CARDS_TYPE = PropTypes.shape({
  land: PropTypes.arrayOf(CARD_TYPE),
  white: PropTypes.arrayOf(CARD_TYPE),
  blue: PropTypes.arrayOf(CARD_TYPE),
  black: PropTypes.arrayOf(CARD_TYPE),
  red: PropTypes.arrayOf(CARD_TYPE),
  green: PropTypes.arrayOf(CARD_TYPE),
  multicolored: PropTypes.arrayOf(CARD_TYPE),
  artifact: PropTypes.arrayOf(CARD_TYPE),
  colorless: PropTypes.arrayOf(CARD_TYPE),
});

const propTypes = {
  name: PropTypes.string.isRequired,
  groupedCards: GROUPED_CARDS_TYPE.isRequired,
};

const OperationsSection = (props) => {
  const { name, groupedCards } = props;

  const cardCategories = map(orderedCategories, (category) => {
    const cards = groupedCards[category];
    if (cards) {
      let cardGroups;
      if (category === 'land') {
        cardGroups = subdivideLands(cards);
      } else {
        cardGroups = [{ name: 'placeholder', cards }];
      }
      return <CardCategory key={category} name={category} cardGroups={cardGroups} />;
    }
    return null;
  });

  const cardCount = reduce(groupedCards, (count, cards) => count + cards.length, 0);

  return (
    <div className="OperationsSection">
      <h2>
        {`${name}: ${cardCount}`}
      </h2>
      {cardCategories}
    </div>
  );
};

OperationsSection.propTypes = propTypes;

export default OperationsSection;
