import React from 'react';
import PropTypes from 'prop-types';

const DeckSelector = (props) => {
  const { decks, select, selectedDeck } = props;
  const deckOptions = decks.map((deck) => (<option key={deck} value={deck}>{deck}</option>));

  const selectDeck = (event) => select(event.target.value);

  return (
    <select className="deckSelector" onChange={selectDeck} value={selectedDeck}>
      {deckOptions}
    </select>
  );
};

DeckSelector.propTypes = {
  decks: PropTypes.arrayOf(PropTypes.string).isRequired,
  select: PropTypes.func.isRequired,
  selectedDeck: PropTypes.string.isRequired,
};

export default DeckSelector;
