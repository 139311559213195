import React from 'react';
import { categoryForCard } from '../util';
import { CARD_TYPE } from '../propTypes';

import './Card.css';

const CardListItem = (props) => {
  const { card } = props;
  const category = categoryForCard(card);
  const className = `Card Card--${category}`;
  return <div className={className}>{card.name}</div>;
};

CardListItem.propTypes = {
  card: CARD_TYPE.isRequired,
};

export default CardListItem;
