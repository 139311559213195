import React from 'react';
import {
  map, groupBy, sortBy,
} from 'lodash';
import staplesData from '../data/staples_cache.json';
import cardData from '../data/card_data_cache.json';
import { categoryForCard, distributeGroups } from '../util';
import OperationsSection from './OperationsSection';
import CardCategory from './CardCategory';

const Binder = () => {
  const staples = sortBy(staplesData.cards);
  const cardInfos = map(staples, (cardName) => cardData.cards[cardName]);
  const groupedCards = groupBy(cardInfos, categoryForCard);
  const distributedCategories = distributeGroups({ groups: groupedCards, pageSize: 4 });
  return (
    <div className="Binder">
      {map(distributedCategories, (category) => {
        const name = category.name.slice(0, 1).toUpperCase() + category.name.slice(1);
        const heading = `${name} - ${category.cards.length} cards - Page ${category.startPage}`
        return (
          <CardCategory key={category.name} name={heading} cardGroups={category.cards} />
        );
      })}
      <OperationsSection name="Binder" groupedCards={groupedCards} />
    </div>
  );
};

export default Binder;
