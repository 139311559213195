import PropTypes from 'prop-types';

const CARD_TYPE = PropTypes.shape({
  name: PropTypes.string,
  color: PropTypes.string,
});

const CARD_GROUP_TYPE = PropTypes.shape({
  name: PropTypes.string,
  cards: PropTypes.arrayOf(CARD_TYPE),
});

export { CARD_TYPE, CARD_GROUP_TYPE };
