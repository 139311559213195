import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Chooser from './components/Chooser';
import Binder from './components/Binder';

import './App.css';

const App = () => (
  <Router>
    <div className="App">
      <h1 className="header">StaplesBinder</h1>
      <Route path="/" exact component={Chooser} />
      <Route path="/binder" component={Binder} />
    </div>
  </Router>
);

export default App;
