import React, { Component } from 'react';

import { keys, without } from 'lodash';
import decksData from '../data/decks.json';
import staplesData from '../data/staples_cache.json';

import DeckSelector from './DeckSelector';
import ActionSelector from './ActionSelector';

import Operations from './Operations';

const { decks } = decksData;
const staples = staplesData.cards;
const allDecks = keys(decks);

class Chooser extends Component {
  state = {
    targetDeck: allDecks[0],
    sourceDeck: allDecks[1],
    unselectedDecks: [...allDecks],
    selectedAction: 'play',
  };

  selectTargetDeck = (deck) => {
    this.setState({
      targetDeck: deck,
      unselectedDecks: without(allDecks, deck),
      selectedAction: 'play',
    });
  };

  selectSourceDeck = (deck) => {
    this.setState({
      sourceDeck: deck,
    });
  };

  selectAction = (action) => {
    this.setState({
      selectedAction: action,
    });
  };

  render() {
    const {
      targetDeck, sourceDeck, selectedAction, unselectedDecks,
    } = this.state;

    return (
      <div className="Chooser">
        <div className="actions">
          <DeckSelector
            decks={allDecks}
            select={this.selectTargetDeck}
            selectedDeck={targetDeck}
          />
          <ActionSelector select={this.selectAction} />
        </div>
        { selectedAction === 'switch'
          ? (
            <div className="sourceDeck">
              <DeckSelector
                decks={unselectedDecks}
                select={this.selectSourceDeck}
                selectedDeck={sourceDeck}
              />
            </div>
          )
          : null}
        { decks[targetDeck]
          && (
            <Operations
              operation={selectedAction}
              staples={staples}
              sourceDeck={decks[sourceDeck]}
              targetDeck={decks[targetDeck]}
            />
          )}
      </div>
    );
  }
}

export default Chooser;
