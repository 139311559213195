import {
  reduce, intersection, difference, toPairs, sortBy,
} from 'lodash';

export const orderedCategories = ['land', 'white', 'blue', 'black', 'red', 'green', 'multicolored', 'artifact', 'colorless'];

export const categoryForCard = (card) => {
  let { colors } = card;
  if (!colors) {
    colors = card.card_faces[0].colors;
  }

  if (colors.length > 1) {
    return 'multicolored';
  }

  if (colors.length === 1) {
    const color = colors[0];
    return {
      W: 'white',
      U: 'blue',
      B: 'black',
      R: 'red',
      G: 'green',
    }[color];
  } if (colors.length === 0) {
    const typeLine = card.type_line;
    const landTypePrefixes = ['Land', 'Basic Land', 'Legendary Snow Land', 'Legendary Land', 'Artifact Land'];
    for (let i = 0; i < landTypePrefixes.length; i += 1) {
      if (typeLine.startsWith(landTypePrefixes[i])) {
        return 'land';
      }
    }
    const artifactTypePrefixes = ['Snow Artifact', 'Artifact', 'Legendary Artifact'];
    for (let i = 0; i < artifactTypePrefixes.length; i += 1) {
      const prefix = artifactTypePrefixes[i];
      if (typeLine.startsWith(prefix)) {
        return 'artifact';
      }
    }
    return 'colorless';
  }
};

export const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

export const distributeGroups = ({ groups, pageSize }) => {
  const groupPairs = sortBy(toPairs(groups), (cat) => orderedCategories.indexOf(cat[0]));
  return reduce(groupPairs, (accum, groupPair, i) => {
    const [name, group] = groupPair;
    const lastStartPage = i === 0 ? 0 : accum[accum.length - 1].startPage;
    const lastGroupSize = i === 0 ? 0 : groupPairs[i - 1][1].length;
    const increment = Math.max(1, Math.ceil(lastGroupSize / pageSize));
    const startPage = lastStartPage + increment;
    return [...accum, { startPage, name, cards: group }];
  }, []);
};

export const buildOperations = (operation, targetDeck, sourceDeck, staples) => {
  if (operation === 'play') {
    return [
      {
        name: 'from-staples',
        cardNames: intersection(targetDeck, staples),
        description: 'Staples',
      },
      {
        name: 'base',
        cardNames: difference(targetDeck, staples),
        description: 'Base cards',
      },
    ];
  } if (operation === 'switch') {
    return [
      {
        name: 'from-source',
        cardNames: intersection(targetDeck, sourceDeck, staples),
        description: 'From current deck',
      },
      {
        name: 'from-staples',
        cardNames: difference(intersection(targetDeck, staples), sourceDeck),
        description: 'From staples',
      },
      {
        name: 'to-staples',
        cardNames: difference(intersection(sourceDeck, staples), targetDeck),
        description: 'To staples',
      },
    ];
  }
  return [];
};
